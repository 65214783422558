import { type TenantConfig, CurrencyId } from "./types";
import { dictionaries } from "./dictionaries";

export const tenants = {
	VENTURA_DEV: {
		name: "Ventura",
		status: "live",
		supportEmail: "support@getcaruso.com",
		postalAddress: "Level 12, AIG Building, 41 Shortland Street, Auckland 1143",
		tenantId: "2T2UtNPyBtUj0d96TIpFM9XxsdW",
		urls: {
			investorPortalUrl: "https://ventura.caruso-dev.getcaruso.dev",
			wholesaleCertificationUrl:
				"https://static.getcaruso.com/2NQlGCNUH3J56YnS09PuCyiZjAc/resources/nz-wholesale-investor-certificate.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/d95a4bdffa499138bfba726a94f580d33d6a1d80.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/aabc5c75654af6d4fbdfccf07c5cde2594f093c5.pdf",
			supportUrl: null,
		},
		investorPortal: {
			assetPath: "/ventura",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#042246",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl:
				"https://cdn.sanity.io/images/etx6e9fd/production/12764fa3b667a766651c53f9c89e31050c573cff-154x33.png",
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	VENTURA_STAGING: {
		name: "Ventura",
		status: "live",
		supportEmail: "support@getcaruso.com",
		postalAddress: "Level 12, AIG Building, 41 Shortland Street, Auckland 1143",
		tenantId: "2T2UwMs69PZcFn1cyReQUia7Esa",
		urls: {
			investorPortalUrl: "https://ventura.caruso-staging.getcaruso.dev",
			wholesaleCertificationUrl:
				"https://static.getcaruso.com/2NQlGCNUH3J56YnS09PuCyiZjAc/resources/nz-wholesale-investor-certificate.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/d95a4bdffa499138bfba726a94f580d33d6a1d80.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/aabc5c75654af6d4fbdfccf07c5cde2594f093c5.pdf",
			supportUrl: null,
		},
		investorPortal: {
			assetPath: "/ventura",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#042246",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl:
				"https://cdn.sanity.io/images/etx6e9fd/production/12764fa3b667a766651c53f9c89e31050c573cff-154x33.png",
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	VENTURA_PRODUCTION: {
		name: "Ventura",
		status: "live",
		supportEmail: "support@getcaruso.com",
		postalAddress: "Level 12, AIG Building, 41 Shortland Street, Auckland 1143",
		tenantId: "2SMc2vwKKLXhxw74mQ8MD0lklYV",
		urls: {
			investorPortalUrl: "https://ventura.caruso-production.getcaruso.dev",
			wholesaleCertificationUrl:
				"https://static.getcaruso.com/2NQlGCNUH3J56YnS09PuCyiZjAc/resources/nz-wholesale-investor-certificate.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/d95a4bdffa499138bfba726a94f580d33d6a1d80.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/aabc5c75654af6d4fbdfccf07c5cde2594f093c5.pdf",
			supportUrl: null,
		},
		investorPortal: {
			assetPath: "/ventura",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#042246",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl:
				"https://cdn.sanity.io/images/etx6e9fd/production/12764fa3b667a766651c53f9c89e31050c573cff-154x33.png",
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	CENTAUR: {
		name: "Centaur",
		status: "live",
		supportEmail: "support@getcaruso.com",
		postalAddress: "Level 12, AIG Building, 41 Shortland Street, Auckland 1143",
		tenantId: "2jl2qv68UmArhCzS5onLwh9UEcG",
		urls: {
			investorPortalUrl: "https://ventura.caruso-production.getcaruso.dev",
			wholesaleCertificationUrl:
				"https://static.getcaruso.com/2NQlGCNUH3J56YnS09PuCyiZjAc/resources/nz-wholesale-investor-certificate.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/d95a4bdffa499138bfba726a94f580d33d6a1d80.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/aabc5c75654af6d4fbdfccf07c5cde2594f093c5.pdf",
			supportUrl: null,
		},
		investorPortal: {
			assetPath: "/ventura",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#042246",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	JASPER: {
		name: "Jasper",
		status: "live",
		supportEmail: "christian.lambert@jasper.io",
		postalAddress: "10 Viaduct Harbour Avenue, Auckland CBD, Auckland 1010",
		tenantId: "2NQlGCNUH3J56YnS09PuCyiZjAc",
		urls: {
			supportUrl: "https://www.jasper.io/about-us/contact-us",
			investorPortalUrl: "https://app.jasper.io",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/eb589dbaff3c27b9773b94f8e5d713d368e74785.pdf",
			termsAndConditionsUrl:
				"https://help.jasper.io/en/articles/3375351-terms-conditions",
			privacyPolicyUrl:
				"https://help.jasper.io/en/articles/3015449-jasper-s-privacy-policy",
		},
		investorPortal: {
			logoSize: "m",
			colors: {
				appHeaderBackground: "#0F46DE",
			},
			assetPath: "/jasper",
			basicAuthUsername: "jasper",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	PMG: {
		name: "PMG",
		status: "demo",
		supportEmail: "",
		urls: {
			supportUrl: "mailto:support@pmg.io",
			investorPortalUrl: null,
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			assetPath: "/pmg",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#323948",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	MACKERSY: {
		name: "Mackersy",
		status: "live",
		tenantId: "2QEGQ2yQRcBywy2UbOxDa6kQ1pg",
		supportEmail: "invest@mackersyproperty.co.nz",
		postalAddress:
			"Five Mile Level 1, Building 8/36 Grant Road, Frankton, Queenstown 9371",
		urls: {
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/ac9aabace79c1586479aaead76a288814addedc6.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/bce1238b5343a76c48f59a843fc026346d13a9f9.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/7af72e2e10d6525b4d94ffd23e6c13047dd053ee.pdf",
			investorPortalUrl: "https://investors.mackersyproperty.co.nz",
			supportUrl: "mailto:invest@mackersyproperty.co.nz",
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		investorPortal: {
			assetPath: "/mackersy",
			logoSize: "xl",
			colors: {
				appHeaderBackground: "#1C1C1C",
			},
			basicAuthUsername: "mackersy",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	MONTARNE: {
		name: "Montarne",
		status: "live",
		tenantId: "2bjhQLuIogZlStebOsZnwLV55zv",
		supportEmail: "info@montarne.com",
		postalAddress: "",
		urls: {
			supportUrl: "mailto:info@montarne.com",
			investorPortalUrl: "",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
		},
		investorPortal: {
			assetPath: "/montarne",
			logoSize: "xl",
			colors: {
				appHeaderBackground: "#11331C",
			},
			basicAuthUsername: "montarne",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	SILVERFIN: {
		name: "Silverfin",
		status: "live",
		tenantId: "2YoeVkFGwnUR0IDwibPJtdb9fkC",
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		supportEmail: "info@silverfin.nz",
		postalAddress: "Level 12, AIG Building, 41 Shortland Street, Auckland 1143",
		urls: {
			supportUrl: "mailto:info@silverfin.nz",
			investorPortalUrl: "https://investors.silverfin.nz",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/569de1ab9c73dd172078fc23f6bb56c811e03b72.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/8d5fbd41856af62ef912cbf7de163f55c4b4db3f.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/ed306f798a19cd355a47e9a1dda849f09c7eb6d4.pdf",
		},
		investorPortal: {
			assetPath: "/silverfin",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#1E4A72",
			},
			basicAuthUsername: "silverfin",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	ROGER_DICKIE: {
		name: "Roger Dickie",
		status: "live",
		tenantId: "2QEGOlXPLto2pPLDB7yHICeVnPH",
		supportEmail: "office@rogerdickie.co.nz",
		postalAddress: "",
		urls: {
			investorPortalUrl: "https://investors.rogerdickie.co.nz",
			supportUrl: "mailto:office@rogerdickie.co.nz",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/e3f04b05b7d6e0bac2ae4fb926b498e4129cb322.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/bc4bb1003367c46f43eb23e5e34ee48dd5dc1b28.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/668b8a1ceff6f81756f73c4450c8362b4fb28626.pdf",
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		investorPortal: {
			assetPath: "/rogerdickie",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#3C3F31",
			},
			basicAuthUsername: "rogerdickie",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	M_7: {
		name: "M7 Real Estate",
		status: "demo",
		urls: {
			supportUrl: "mailto:info@m7re.eu",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/m7",
			logoSize: "xl",
			colors: {
				appHeaderBackground: "#002D73",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	FORT_HILL: {
		name: "Forthill Property",
		status: "demo",
		supportEmail: "info@forthill.co.nz",
		investorPortal: {
			logoSize: "s",
			assetPath: "/forthill",
			colors: {
				appHeaderBackground: "#2B086C",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:info@forthill.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	MARQUETTE: {
		name: "Marquette Properties",
		status: "live",
		tenantId: "2QEGRkZoCqKPoQJRtoGPC7LqGTQ",
		postalAddress: "Level 31/10 Eagle St, Brisbane City QLD 4000, Australia",
		supportEmail: "kate@marquette.com.au",
		urls: {
			investorPortalUrl: "https://investors.marquette.com.au",
			supportUrl: "mailto:kate@marquette.com.au",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/84795a55ba07dbb67acaf7bf770726f65ab33489.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/553f65d9b44a2b0ee1c749fabaaaf062149dd032.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/4ccb8301d797ab76df7c3cdb26a11ca48fb8f13e.pdf",
		},
		investorPortal: {
			assetPath: "/marquette",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#222222",
			},
			basicAuthUsername: "marquette",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: false,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.AUD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	FIRST_LIGHT: {
		name: "First Light Capital",
		status: "demo",
		urls: {
			supportUrl: "mailto:admin@firstlightcapital.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			logoSize: "xl",
			assetPath: "/firstlight",
			colors: {
				appHeaderBackground: "#152C2D",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	FORTLAND: {
		name: "Fortland",
		status: "demo",
		urls: {
			supportUrl: "mailto:investment@fortland.capital",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/fortland",
			logoSize: "xl",
			colors: {
				appHeaderBackground: "#13352D",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	CENTURIA: {
		name: "Centuria",
		status: "demo",
		urls: {
			supportUrl: "mailto:enquiries@centuria.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/centuria",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#050505",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	BEGROUP: {
		name: "BE Group",
		status: "live",
		tenantId: "2QEGMkXujOKd9Q8U5On7XZD4kLr",
		supportEmail: "investors@thebegroup.co.nz",
		postalAddress:
			"Level 2, 17 Falcon Street, Parnell, Auckland 1052 PO Box 137103 Parnell, Auckland 1051",
		urls: {
			investorPortalUrl: "https://investors.thebegroup.co.nz",
			supportUrl: "mailto:investors@thebegroup.co.nz",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/fe4fb9eb0335db9236fd8139cb6d7d075451d03b.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/2f425f51640e747f46d8cb1116643a4381f4cb8d.pdf",
			wholesaleCertificationUrl: null,
		},
		investorPortal: {
			assetPath: "/begroup",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#404041",
			},
			basicAuthUsername: "begroup",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	CRAIGMORE: {
		name: "Craigmore",
		status: "live",
		tenantId: "2jl2qtz8nmfdRb7Jmml3BKHRvK7",
		supportEmail: "admin@craigmore.com",
		postalAddress: "",
		investorPortal: {
			assetPath: "/craigmore",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#004543",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			investorPortalUrl: "https://investors.craigmore.com",
			supportUrl: "mailto:admin@craigmore.com",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	IP_GENERATION: {
		name: "IP Generation",
		status: "live",
		tenantId: "2jl31LSg9s4Tyw9mlbrxuxyyhyq",
		supportEmail: "admin@ipgeneration.com.au",
		postalAddress: "Level 19/627 Chapel St, South Yarra VIC 3141, Australia",
		investorPortal: {
			assetPath: "/ipgeneration",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#231F20",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: false,
		},
		urls: {
			supportUrl: "mailto:admin@ipgeneration.com.au",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/f8cdfb9cd291488aa36a462f579e63e6f10c1549.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/c5dc293c3e44861ac32dd34eb0a3ac73da49212c.pdf",
			wholesaleCertificationUrl: null,
			investorPortalUrl: "portal.ipgeneration.com.au",
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.AUD,
			anvilSubscriptionDocTemplateId: "AsEUO7mlfTprrJGC3KaU",
			anvilSubscriptionDocLogoUrl:
				"https://cdn.sanity.io/images/etx6e9fd/production/467dc381a62617bd6643b752a39cc01ffc9283b1-360x177.png",
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	STRIDE: {
		name: "Stride",
		status: "demo",
		urls: {
			supportUrl: "mailto:admin@strideproperty.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/stride",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#E1E4F1",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	TODD: {
		name: "Todd Property",
		status: "demo",
		urls: {
			supportUrl: "mailto:amy.mckenzie@toddproperty.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/todd",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#193960",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	OYSTER: {
		name: "Oyster Property Group",
		status: "demo",
		supportEmail: "hello@oystergroup.co.nz",
		postalAddress: "",
		urls: {
			investorPortalUrl: "https://investors.oystergroup.co.nz",
			supportUrl: "mailto:hello@oystergroup.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
		},
		investorPortal: {
			assetPath: "/oyster",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#081232",
			},
			basicAuthUsername: "oystergroup",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	RESIDENT: {
		name: "Resident Properties",
		status: "demo",
		urls: {
			supportUrl: "mailto:",
			investorPortalUrl: "https://investors.residentproperties.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
		},
		investorPortal: {
			assetPath: "/resident",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#252C0C",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	PALLAS: {
		name: "Pallas Capital",
		status: "demo",
		urls: {
			investorPortalUrl: "https://investors.pallas.capital",
			supportUrl: "",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
		},
		investorPortal: {
			assetPath: "/pallas",
			logoSize: "xl",
			colors: {
				appHeaderBackground: "#192E3E",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	FORZA: {
		name: "Forza Capital",
		status: "demo",
		urls: {
			supportUrl: "mailto:info@forzacapital.com.au",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			logoSize: "s",
			assetPath: `/forza`,
			colors: {
				appHeaderBackground: "#19222B",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	MAINLAND: {
		name: "Mainland Capital",
		status: "live",
		tenantId: "2gREEEgRdIBr7SAmCjhm4qiSLe2",
		supportEmail: "info@mcap.co.nz",
		postalAddress: "",
		urls: {
			supportUrl: "mailto:info@mcap.co.nz",
			investorPortalUrl: "https://investors.mcap.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
		},
		investorPortal: {
			assetPath: "/mainland",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#222222",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	CENTENNIAL: {
		name: "Centennial",
		status: "demo",
		urls: {
			supportUrl: "mailto:info@centennial.com.au",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/centennial",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#000000",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	THREE_CAPITAL: {
		name: "3 Capital",
		status: "live",
		tenantId: "2Uzhqfs4gBnFYnOnpfVgQ2mbbHT",
		supportEmail: "info@3capital.co.nz",
		postalAddress: "",
		urls: {
			investorPortalUrl: "https://investors.3capital.co.nz",
			supportUrl: "mailto:info@3capital.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/044028150fd0a5a378c7069ebd5ae2fbe4f69d6c.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/5e7b2e9f09ffbc0f7a1e15418b7558ec364102be.pdf",
			wholesaleCertificationUrl: null,
		},
		investorPortal: {
			assetPath: "/threeCapital",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#2A2828",
			},
			basicAuthUsername: "3capital",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	ALPHA_FIRST: {
		name: "Alpha First",
		status: "live",
		tenantId: "2jl35UuNvTGWggx7kHiO8ARSte6",
		supportEmail: "info@alphafirst.co.nz",
		postalAddress: "104/86 Alpha Street, Cambridge 3434",
		urls: {
			supportUrl: "mailto:investors@alphafirst.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/0b92d218d9a86f298a7aff0f19f2b220e9e7bb09.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/6e8bc6204431247f57d3de4205b052a638290e73.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/d14e56405fb72e7e3070283d29a9dfc4bdcb3cbb.pdf",
			investorPortalUrl: "https://invest.alphafirst.co.nz",
		},
		investorPortal: {
			assetPath: "/alphaFirst",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#093266",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	ALPHA_SEVEN: {
		name: "Alpha Seven",
		status: "live",
		tenantId: "2pxP0TNhCJIzB4XwpvxFfWDJ2ur",
		supportEmail: "info@alphasevenenergy.com",
		postalAddress: "Suite 3611, Level 36/1 Macquarie Place, Sydney NSW 2000",
		urls: {
			supportUrl: "mailto:info@alphasevenenergy.com",
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
			wholesaleCertificationUrl: null,
			investorPortalUrl: "https://investors.alphasevenenergy.com",
		},
		investorPortal: {
			assetPath: "/alphaseven",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#24272d",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	KOAU: {
		name: "Koau Capital Partners",
		status: "demo",
		urls: {
			supportUrl: "mailto:admin@koau.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/koau",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#333333",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	APRIL_GROUP: {
		name: "April Group",
		status: "demo",
		urls: {
			supportUrl: "mailto:info@aprilgroup.com.au",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/aprilGroup",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#000000",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	TITUS_GROUP: {
		name: "Titus Group",
		status: "demo",
		urls: {
			supportUrl: "mailto:accounts@titusgroup.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/titusGroup",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#07323E",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	WILLIAMS_CORP: {
		name: "Williams Corporation",
		status: "demo",
		urls: {
			supportUrl: "mailto:office@williamscorporation.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/williamsCorp",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#07323E",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	JARDEN: {
		name: "Jarden",
		status: "demo",
		urls: {
			supportUrl: "mailto:info@jarden.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			logoSize: "s",
			assetPath: "/jarden",
			colors: {
				appHeaderBackground: "#29323E",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	FMT: {
		name: "First Mortgage Trust",
		status: "live",
		tenantId: "2bKsvumoHLP6Ib3bppvNo7v1nub",
		supportEmail: "invest@fmt.co.nz",
		postalAddress: "",
		urls: {
			investorPortalUrl: "https://investors.firstmortgagetrust.co.nz",
			supportUrl: "mailto:invest@fmt.co.nz",
			termsAndConditionsUrl: "https://fmt.co.nz/investor-portal-terms/",
			privacyPolicyUrl: "https://fmt.co.nz/privacy-statement/",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
		},
		investorPortal: {
			logoSize: "m",
			assetPath: "/fmt",
			colors: {
				appHeaderBackground: "#1B3F8F",
			},
			basicAuthUsername: "fmt",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	INDIGOBLUE: {
		name: "Indigoblue Capital Corporation",
		status: "demo",
		urls: {
			supportUrl: "mailto:investors@indigoblue.ca",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/indigoblue",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#03034E",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	GREENMOUNT: {
		name: "GreenMount",
		status: "live",
		tenantId: "2eeYDCnX5Zpn1EB6OoOnjAF1w3x",
		supportEmail: "info@greenmount.com",
		postalAddress: "",
		urls: {
			investorPortalUrl: "https://investors.greenmount.com",
			supportUrl: "mailto:info@greenmount.com",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
		},
		investorPortal: {
			assetPath: "/greenmount",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#0C4341",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	WESTBRIDGE: {
		name: "Westbridge",
		status: "demo",
		urls: {
			supportUrl: "mailto:info@westbridgefunds.com.au",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/westbridge",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#233E54",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	WENTWORTH: {
		name: "Wentworth Capital",
		status: "demo",
		urls: {
			supportUrl: "mailto:info@wentworthcp.com.au",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/wentworth",
			logoSize: "xl",
			colors: {
				appHeaderBackground: "#000000",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	DIRECT_CAPITAL: {
		name: "Direct Capital",
		status: "demo",
		urls: {
			supportUrl: "mailto:dcm@directcapital.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/directCapital",
			logoSize: "xl",
			colors: {
				appHeaderBackground: "#006737",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	GAZE: {
		name: "Gaze Property",
		status: "live",
		tenantId: "2YoeTsrUbRCTgqB4SgN2yib2AVJ",
		supportEmail: "invest@gaze.co.nz",
		postalAddress: "",
		urls: {
			investorPortalUrl: "https://investors.gaze.co.nz",
			supportUrl: "mailto:invest@gaze.co.nz",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/5a37c73f3c6581ed7f91d3e63f839ac60e011b0c.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/f25b0c05e7ba57ae7778735176b489388b82e0d7.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/31d530de8a96d5e9d372637df5350b9933fed6ec.pdf",
		},
		investorPortal: {
			assetPath: "/gaze",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#5F171A",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	BALMAIN: {
		name: "Balmain",
		status: "live",
		tenantId: "2gREAIUqf2YPb31J3WKtPT655j9",
		supportEmail: "info@balmain.com.au",
		postalAddress: "",
		urls: {
			investorPortalUrl: "https://investors.balmain.com.au",
			supportUrl: "mailto:info@balmain.com.au",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/d79ca41c7e25bf5c980090f91c138d04133801be.pdf",
		},
		investorPortal: {
			assetPath: "/balmain",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#222222",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: false,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.AUD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	CAMCO: {
		name: "Camco",
		status: "demo",
		urls: {
			supportUrl: "mailto:info@camco.fm",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		investorPortal: {
			assetPath: "/camco",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#2C2F48",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	FINBASE: {
		name: "Finbase",
		status: "live",
		tenantId: "2bjh6N7xSr2D0E983FIJdsC0jlo",
		supportEmail: "pernell@finbase.nz",
		postalAddress: "",
		investorPortal: {
			logoSize: "lg",
			assetPath: `/finbase`,
			colors: {
				appHeaderBackground: "#1F2753",
			},
			basicAuthUsername: "finbase",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/9e80966b9ee8f810592c28c3067366d0df2a8498.pdf",
			investorPortalUrl: "https://investors.finbase.nz",
			supportUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	MY_FARM_INVESTMENTS: {
		name: "MyFarm Investments",
		status: "demo",
		investorPortal: {
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#966A4D",
			},
			assetPath: `/my-farm-investments`,
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:comms@myfarm.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	ERSKINE_OWEN: {
		name: "Erskine Owen",
		status: "demo",
		investorPortal: {
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#091E34",
			},
			assetPath: `/erskine-owen`,
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:info@erskineowen.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	APIL_GROUP: {
		name: "APIL Group",
		status: "live",
		tenantId: "2gREEAjl2BAKxNtn7oZeR0Ndu67",
		supportEmail: "info@apilgroup.com",
		postalAddress: "Level 7, 30 The Esplanade Perth, Western Australia 6000",
		investorPortal: {
			assetPath: "/apilgroup",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#290F2E",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:info@apilgroup.com",
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
			wholesaleCertificationUrl: null,
			investorPortalUrl: null,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.AUD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	DINIMUS: {
		name: "Dinimus",
		status: "live",
		tenantId: "2g7nbLw30uKs8oEWxnKoUXfJtcF",
		supportEmail: "info@dinimus.com.au",
		postalAddress: "",
		investorPortal: {
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#153240",
			},
			assetPath: "/dinimus",
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: false,
		},
		urls: {
			investorPortalUrl: "https://investors.dinimus.com.au",
			supportUrl: "mailto:dcf@dinimus.com.au",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/66c1608dc7108ba320f7fec7a6ec031d17cbf762.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/1b1edc0a7dbce1d518897ba15bb351e1e09c9dc1.pdf",
			wholesaleCertificationUrl: null,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.AUD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	ASHE_MORGAN: {
		name: "Ashe Morgan",
		status: "demo",
		investorPortal: {
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#124537",
			},
			assetPath: "/ashemorgan",
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:info@ashemorgan.com.au",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	COI_CAPITAL: {
		name: "COI Capital",
		status: "demo",
		investorPortal: {
			logoSize: "s",
			colors: {
				appHeaderBackground: "#102827",
			},
			assetPath: "/coicapital",
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:enquiries@coicapital.com.au",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	QUARRY_CAPITAL: {
		name: "Quarry Capital",
		status: "live",
		tenantId: "2gREAFM7PcS8SytesvVYrOeKAGp",
		supportEmail: "syndicates@quarrycapital.co.nz",
		postalAddress: "",
		investorPortal: {
			logoSize: "s",
			colors: {
				appHeaderBackground: "#2B3545",
			},
			assetPath: "/quarrycapital",
			basicAuthUsername: "quarrycapital",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			investorPortalUrl: "https://investors.quarrycapital.co.nz",
			supportUrl: "mailto:syndicates@quarrycapital.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/6dc7037421a26048e5c2c7c65011a01b56fc5e14.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/9e0edc70314602d56fd9bb06a98636e430677e47.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/07317c46a14982f00e5c0f5e1c7237de15dfbbd5.pdf",
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	CAPITAL_PROPERTY: {
		name: "Capital Property",
		status: "demo",
		investorPortal: {
			logoSize: "2xl",
			colors: {
				appHeaderBackground: "#000000",
			},
			assetPath: "/capitalproperty",
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	PEARLFISHER_CAPITAL: {
		name: "Pearlfisher Capital",
		status: "live",
		tenantId: "2jl33cV0fTN5f9A9iU2rTIqxHHC",
		postalAddress:
			"Level 1 Kiwi Tavern Building 23 Britomart Place, Britomart Precinct Auckland 1010",
		supportEmail: "investors@pearlfishercapital.co.nz",
		investorPortal: {
			logoSize: "xl",
			colors: {
				appHeaderBackground: "#005480",
			},
			assetPath: "/pearlfishercapital",
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:investors@pearlfishercapital.co.nz",
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
			wholesaleCertificationUrl: null,
			investorPortalUrl: null,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	SCARBOROUGH_GROUP: {
		name: "Scarborough Group",
		status: "demo",
		investorPortal: {
			logoSize: "m",
			colors: {
				appHeaderBackground: "#0B410E",
			},
			assetPath: "/scarborough",
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:info@scarboroughgroup.co.nz",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/58022f79e5e898474acb58b850ad62cb8ddc3957.pdf",
			investorPortalUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	PARKSTONE: {
		name: "Parkstone Funds management",
		status: "live",
		tenantId: "2gREALNIJhNymFcX3w36t1RcZKM",
		supportEmail: "parkstone.com.au",
		postalAddress: "Suite 3/ 1-5 Wakefield Street Kent Town  SA  5067",
		investorPortal: {
			assetPath: "/parkstone",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#294B3E",
			},
			basicAuthUsername: "parkstone",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: false,
		},
		urls: {
			investorPortalUrl: "https://investors.parkstone.com.au",
			supportUrl: "mailto:info@parkstone.com.au",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/1b7d919bfc753bef69e68b0802cd5917a0c47ea4.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/a9d6d76141ca5967d214b3fefae85bca0451ca8a.pdf",
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.AUD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	ENDOW: {
		name: "Endow property finance",
		status: "live",
		tenantId: "2jl2qsK4NYyLx74WyImXSwCr3xp",
		supportEmail: "team@endow.co.nz",
		postalAddress: "Floor 1, 1 The Strand, Takapuna, Auckland, New Zealand",
		investorPortal: {
			assetPath: "/endow",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#23334a",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:team@endow.co.nz",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/0e6a36fdf829f68820bfcd820dafcbbff660e6b7.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/42f5f0a8dfd7f3344e26647996ec4af6408db737.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/2261569f3f9554353012af399d0209e1ed29c8eb.pdf",
			investorPortalUrl: "http://investors.endow.co.nz",
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	MOUNT_CAPITAL: {
		name: "Mount Capital",
		status: "demo",
		investorPortal: {
			assetPath: "/mountcapital",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#1e2839",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:enquiry@mountcapital.co.nz",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
			investorPortalUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	PROVINCIA: {
		name: "Provincia",
		status: "demo",
		investorPortal: {
			assetPath: "/provincia",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#018196",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:enquiry@provincia.co.nz",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
			investorPortalUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	IRONSTATE: {
		name: "Ironstate Capital Partners",
		status: "live",
		tenantId: "2gREALgH7tJRGZlwwydXMiSdj3Z",
		supportEmail: "enquiries@ironstate.com.au",
		postalAddress: "",
		investorPortal: {
			assetPath: "/ironstate",
			logoSize: "2xl",
			colors: {
				appHeaderBackground: "#0C1824",
			},
			basicAuthUsername: "ironstate",
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: false,
		},
		urls: {
			investorPortalUrl: "https://investors.ironstate.com.au",
			supportUrl: "mailto:enquiries@ironstate.com.au",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/f6ae1c1d2098790bb8a2cd85faa444cf793d46ca.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/1d0d3020ece8edafd43644f51b4c9017647b3414.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/12663e9f7937a493c3c96b65c6f4fe528a980dab.pdf",
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.AUD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	HESPERIA: {
		name: "Hesperia",
		status: "demo",
		investorPortal: {
			assetPath: "/hesperia",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#e36323",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:info@hesperia.com.au",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
			investorPortalUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	FUNDSTREAM: {
		name: "Fundstream",
		status: "live",
		tenantId: "2jl31Je5EbCAl1oueAYehrdn4Bt",
		supportEmail: "invest@fundstream.com.au",
		postalAddress: "55 Clarence Street, Sydney NSW 2000",
		investorPortal: {
			assetPath: "/fundstream",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#04103f",
			},
			basicAuthUsername: null,
			hasVectorLogo: true,
			collectWHT: true,
			collectPIR: false,
		},
		urls: {
			supportUrl: "mailto:invest@fundstream.com.au",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/4b114ba9f079897b38db66dda82ec0f4979e24ac.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/5817be52e66d3121316c5c56eddcd334e42d5517.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/e8c6d5073ab127a7cf2bc826cf43e0f72841ada0.pdf",
			investorPortalUrl: "https://invest.fundstream.com.au",
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.AUD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	COULOIR_CAPITAL: {
		name: "Couloir Capital",
		status: "demo",
		investorPortal: {
			assetPath: "/couloircapital",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#000000",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:kristie.brown@couloircapital.com.au",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
			investorPortalUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	INVESTEC: {
		name: "Investec",
		status: "demo",
		investorPortal: {
			assetPath: "/investec",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#051b63",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:enquiries@dave@dwr.co.nz",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
			investorPortalUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	WILSONS_ADVISORY: {
		name: "Wilsons Advisory",
		status: "demo",
		investorPortal: {
			assetPath: "/wilsonsadvisory",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#000000",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:info@wilsonsadvisory.com.au",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
			investorPortalUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	ELANOR: {
		name: "Elanor investors group",
		status: "demo",
		investorPortal: {
			assetPath: "/elanor",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#6a1b19",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			supportUrl: "mailto:elanor@automic.com.au",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
			investorPortalUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	ORIGIN_CAPITAL_PARTNERS: {
		name: "Origin Capital Partners",
		status: "demo",
		investorPortal: {
			assetPath: "/origincapitalpartners",
			logoSize: "2xl",
			colors: {
				appHeaderBackground: "#7f133b",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		urls: {
			privacyPolicyUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			supportUrl: "",
			investorPortalUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	KIN_GROUP: {
		name: "Kin Group",
		status: "demo",
		urls: {
			supportUrl: "",
			investorPortalUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
		},
		investorPortal: {
			assetPath: "/kingroup",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#005949",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	PLUS_MORTGAGE_TRUST: {
		name: "Plus Mortgage Trust",
		status: "demo",
		urls: {
			supportUrl: "",
			investorPortalUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
		},
		investorPortal: {
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#1b272c",
			},
			assetPath: "/plusmortgagetrust",
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	CENTURIA_BASS: {
		name: "Centuria Bass",
		status: "demo",
		urls: {
			supportUrl: "",
			investorPortalUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
		},
		investorPortal: {
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#26587a",
			},
			assetPath: "/centuriabass",
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	CASTLEROCK: {
		name: "Castlerock",
		status: "demo",
		urls: {
			supportUrl: "",
			investorPortalUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
		},
		investorPortal: {
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#222222",
			},
			assetPath: "/castlerock",
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	MARSHALL_INVESTMENTS: {
		name: "Marshall Investments",
		status: "live",
		tenantId: "2jl34lUqx5uDtDkiJ8Bd8stD8s8",
		postalAddress:
			"Suite 1, Level 12, 53 Martin Place Sydney NSW 2000 Australia",
		supportEmail: "investors@marshall.com.au",
		urls: {
			supportUrl: "mailto:investors@marshall.com.au",
			investorPortalUrl: "https://investors.marshall.com.au",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/d695e98b7fdc986590fa10438233cfbb122e9003.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/9af823341d1ed0881b8120aeec507dd290463105.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/0f3f1ccd582ab8c9009d7865eb1f1829e942a611.pdf",
		},
		investorPortal: {
			assetPath: "/marshallinvestments",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#2B4E3A",
			},
			basicAuthUsername: null,
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: false,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.AUD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	WOLFBROOK_CAPITAL: {
		name: "Wolfbrook Capital",
		status: "demo",
		urls: {
			supportUrl: "",
			investorPortalUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
		},
		investorPortal: {
			assetPath: "/wolfbrookcapital",
			logoSize: "2xl",
			basicAuthUsername: null,
			colors: {
				appHeaderBackground: "#1d1c1b",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	NZMS: {
		name: "NZMS",
		status: "demo",
		urls: {
			supportUrl: "",
			investorPortalUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
		},
		investorPortal: {
			assetPath: "/nzms",
			logoSize: "lg",
			basicAuthUsername: null,
			colors: {
				appHeaderBackground: "#292929",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	ACURE: {
		name: "Acure Asset Management",
		status: "demo",
		urls: {
			supportUrl: "",
			investorPortalUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
		},
		investorPortal: {
			assetPath: "/acure",
			logoSize: "s",
			basicAuthUsername: null,
			colors: {
				appHeaderBackground: "#19130b",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	STURT_CAPITAL: {
		name: "Sturt Capital",
		status: "demo",
		urls: {
			supportUrl: "",
			investorPortalUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
		},
		investorPortal: {
			assetPath: "/sturtcapital",
			logoSize: "lg",
			basicAuthUsername: null,
			colors: {
				appHeaderBackground: "#114369",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	WHOLECAP: {
		name: "Wholecap",
		status: "demo",
		urls: {
			supportUrl: "",
			investorPortalUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
		},
		investorPortal: {
			assetPath: "/wholecap",
			logoSize: "lg",
			basicAuthUsername: null,
			colors: {
				appHeaderBackground: "#000000",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	RPM_CAPITAL: {
		name: "RPM Capital",
		status: "demo",
		urls: {
			supportUrl: "",
			investorPortalUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
		},
		investorPortal: {
			assetPath: "/rpmcapital",
			logoSize: "s",
			basicAuthUsername: null,
			colors: {
				appHeaderBackground: "#4b9948",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	DORADO_CAPITAL: {
		name: "Dorado",
		status: "demo",
		urls: {
			supportUrl: "mailto:invest@dorado.com.au",
			investorPortalUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
		},
		investorPortal: {
			assetPath: "/doradocapital",
			logoSize: "lg",
			basicAuthUsername: null,
			colors: {
				appHeaderBackground: "#212736",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: false,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	ALCEON: {
		name: "Alceon",
		status: "demo",
		urls: {
			supportUrl: "",
			investorPortalUrl: "",
			wholesaleCertificationUrl: "",
			termsAndConditionsUrl: "",
			privacyPolicyUrl: "",
		},
		investorPortal: {
			assetPath: "/alceon",
			logoSize: "lg",
			basicAuthUsername: null,
			colors: {
				appHeaderBackground: "#00457c",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	B_MOSES: {
		name: "B. Moses Asset Management",
		status: "live",
		tenantId: "2jl31IjHSpsfiSAxESLRhSFHken",
		supportEmail: "info@bmoses.com.au",
		postalAddress: "L 22, Suite 3, 264 George St, SYDNEY, NSW, 2000",
		urls: {
			investorPortalUrl: "https://investors.bmoses.com.au",
			supportUrl: "mailto:info@bmoses.com.au",
			wholesaleCertificationUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/d9f9185acba78189fde46dabb4755ffff805e834.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/bb779f8b7248afc3ab77a3cdf4e6e88549d0e65c.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/6dd72fddabc0836dee65d04b988e4132d768b5cb.pdf",
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/bmoses",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#000000",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: false,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.AUD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	ROC_PARTNERS: {
		name: "Roc Partners",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@rocp.com",
			// Todo: Add policy documents
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/rocp",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#192736",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	RAM_GROUP: {
		name: "Ram Group",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@ramgroup.com.au",
			// Todo: Add policy documents
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/ramgroup",
			logoSize: "m",
			colors: {
				appHeaderBackground: "#2e3956",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	PROPERTY_FUNDING_NZ: {
		name: "Property Funding NZ",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@propertyfunding.co.nz",
			// Todo: Add policy documents
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/propertyfundingnz",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#413631",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	FUNDING_PRO: {
		name: "Funding Pro",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@fundingpro.com.au",
			// Todo: Add policy documents
			wholesaleCertificationUrl:
				"https://static.getcaruso.com/2NQlGCNUH3J56YnS09PuCyiZjAc/resources/nz-wholesale-investor-certificate.pdf",
			termsAndConditionsUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/d95a4bdffa499138bfba726a94f580d33d6a1d80.pdf",
			privacyPolicyUrl:
				"https://cdn.sanity.io/files/etx6e9fd/production/aabc5c75654af6d4fbdfccf07c5cde2594f093c5.pdf",
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/fundingpro",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#092b3a",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	IPM_PROPERTY: {
		name: "IPM Property",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@ipmproperty.com.au",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/ipmProperty",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#143264",
			},
			hasVectorLogo: true,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	MIDLANDS_PROPERTY: {
		name: "Midlands Property",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:investments@mmt.net.nz",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/midlandsProperty",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#1E3D5A",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	CLASSIC_COLLECTIVES: {
		name: "Classic Collectives",
		status: "live",
		tenantId: "2pxP0SYtS36hsgqAsmTOnWPIVV9",
		postalAddress:
			"Unit 6/332 Maunganui Road, Mt Maunganui, 3116, Aotearoa, New Zealand",
		supportEmail: "invest@classiccollectives.co.nz",
		urls: {
			investorPortalUrl: "https://invest.classiccollectives.co.nz",
			supportUrl: "mailto:invest@classiccollectives.co.nz",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/classicCollectives",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#002D5B",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
	},
	CHANCEVOIGHT: {
		name: "Chance Voight",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:investors@chancevoight.com",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/chancevoight",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#19345E",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	NORFOLK_TRUST: {
		name: "Norfolk Trust",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@norfolktrust.com.au",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			hasVectorLogo: false,
			basicAuthUsername: null,
			assetPath: "/norfolktrust",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#423d38",
			},
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	KODA_CAPITAL: {
		name: "Koda Capital",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@kodacapital.com",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/koda",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#001f41",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	IP_PARTNERS: {
		name: "IP Partners",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:service@ipartners.com.au",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/ippartners",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#222222",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	TRILOGY_FUNDS: {
		name: "Trilogy Funds",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:investorrelations@trilogyfunds.com.au",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/trilogyfunds",
			logoSize: "xl",
			colors: {
				appHeaderBackground: "#162944",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	ALL_CAP_SECURITIES: {
		name: "All Cap Securities",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@allcapsecurities.com.au",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/allcap",
			logoSize: "lg",
			hasVectorLogo: false,
			colors: {
				appHeaderBackground: "#304745",
			},
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	MP_GROUP: {
		name: "MP Group",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@mpgroup.com.au",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/mpgroup",
			logoSize: "xl",
			colors: {
				appHeaderBackground: "#000000",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	SENIOR_TRUST: {
		name: "Senior Trust",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@seniortrust.com.au",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/seniortrust",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#334357",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: false,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	WHITE_ACORN_CAPITAL: {
		name: "White Acorn Capital",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:daniel@whiteacorncapital.com.au",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/whiteacorncapital",
			logoSize: "xl",
			colors: {
				appHeaderBackground: "#061532",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	PROPERLI: {
		name: "Properli",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@properli.co.nz",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/properli",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#183b35",
			},
			hasVectorLogo: false,
			collectWHT: false,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	ARGUS_PARTNERS: {
		name: "Argus Partners",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@arguspartners.com.au",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/arguspartners",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#001738",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: false,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	CLASSIC_CAPITAL: {
		name: "Classic Capital",
		status: "live",
		tenantId: "2jl363qDRCHQED81HEnE22rDcaq",
		postalAddress: "160 17th Avenue, Tauranga South, Tauranga 3112",
		supportEmail: "info@classiccapital.co.nz",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@classiccapital.co.nz",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/classiccapital",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#0f2a4d",
			},
			hasVectorLogo: true,
			collectWHT: true,
			collectPIR: true,
		},
		adminApp: {
			defaultCurrencyId: CurrencyId.NZD,
			anvilSubscriptionDocTemplateId: null,
			anvilSubscriptionDocLogoUrl: null,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	DURANT_WYOT: {
		name: "Durant Wyot",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:enquiry@durantwyotcapital.com",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/durantwyot",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#333333",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: false,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	MILLBROOK_GROUP: {
		name: "Millbrook Group",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@millbrookgroup.com.au",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/millbrookgroup",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#112538",
			},
			hasVectorLogo: true,
			collectWHT: true,
			collectPIR: false,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
	WILLIS_BOND: {
		name: "Willis Bond",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@willisbond.co.nz",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/willisbond",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#012837",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	FARM_RIGHT: {
		name: "Farm Right",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:office@farmright.co.nz",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/farmright",
			logoSize: "s",
			colors: {
				appHeaderBackground: "#01763F",
			},
			hasVectorLogo: false,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	OCTOPUS_INVESTMENTS: {
		name: "Octopus Investments",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@octopusinvestments.com",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/octopusinvestments",
			logoSize: "2xl",
			colors: {
				appHeaderBackground: "#1a1d41",
			},
			hasVectorLogo: true,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.NZ,
		country: "NZ",
	},
	REMARA: {
		name: "Remara Asset Management",
		status: "demo",
		urls: {
			investorPortalUrl: null,
			supportUrl: "mailto:info@remara.com",
			wholesaleCertificationUrl: null,
			termsAndConditionsUrl: null,
			privacyPolicyUrl: null,
		},
		investorPortal: {
			basicAuthUsername: null,
			assetPath: "/remara",
			logoSize: "lg",
			colors: {
				appHeaderBackground: "#461E64",
			},
			hasVectorLogo: true,
			collectWHT: true,
			collectPIR: true,
		},
		translations: dictionaries.AU,
		country: "AU",
	},
} as const satisfies Record<string, TenantConfig>;

export type Tenant = (typeof tenants)[keyof typeof tenants];
export type LiveTenant = Extract<Tenant, { status: "live" }>;
export type DemoTenant = Extract<Tenant, { status: "demo" }>;

export type TenantKey = keyof typeof tenants;

export type LiveTenantKey = {
	[K in keyof typeof tenants]: (typeof tenants)[K]["status"] extends "live"
		? K
		: never;
}[keyof typeof tenants];

export type TenantId = (typeof tenants)[LiveTenantKey]["tenantId"];
