import React, { useRef } from "react";
import RouterLink from "next/link";

import {
	Link,
	LinkVariant,
	IconAccount,
	IconExit,
	IconMailStroke,
	IconHelp,
} from "@jasperlabs/jux-next";

import clsx from "clsx";
import RenderIfCondition from "components/organisms/RenderIfCondition";
import { useLogOut } from "contexts/logOut";
import ContactUs from "tenants/components/TenantSupportLink";
import { CARUSO_HELP_CENTER } from "constants/external-links";
import { motion } from "framer-motion";
import NotificationsCountPill from "components/layout/AppHeader/NotificationsLink/NotificationsCountPill";
import { BehindFeature } from "components/utilities/featureFlag";
import {
	TenantPrivacyPolicyLink,
	TenantTermsAndConditionsLink,
} from "tenants/components";

import { InvestingEntitiesMobileDropdownMenu } from "../InvestingEntitiesDropdownMenu";

const navAnimation = {
	wrapper: {
		hidden: {
			x: "100%",
		},
		visible: {
			x: "0%",
		},
	},
	inner: {
		hidden: {
			opacity: 0,
		},
		visible: {
			opacity: 1,
			transition: {
				duration: 0.5,
				delay: 0.5,
			},
		},
	},
};

const NavItemWithCountPill = ({
	name,
	count,
}: {
	name: string;
	count: number;
}) => (
	<div className="flex items-center gap-1">
		{name}
		<RenderIfCondition condition={count > 0}>
			<div className="flex items-center justify-center p-[6px] h-4 min-w-[16px] bg-primary-400 text-white rounded-full text-xs">
				{count}
			</div>
		</RenderIfCondition>
	</div>
);

const LINK_STYLES =
	"font-semibold text-subheading-sm flex w-full h-8 items-center text-black";

const DefaultNavigation = ({
	onClose,
	offersCount,
	sellOrdersCount,
	activeOrdersCount,
	hasOffers,
}: {
	onClose: () => void;
	hasOffers: boolean;
	offersCount: number;
	sellOrdersCount: number;
	activeOrdersCount: number;
}) => {
	const navRef = useRef(null);
	const { logOut } = useLogOut();

	const hasSellOrders = sellOrdersCount > 0;

	return (
		<motion.nav
			key="motionNav"
			id="primary-mobile-nav"
			data-testid="primary-mobile-nav"
			aria-label="Mobile navigation"
			initial="hidden"
			animate="visible"
			exit="hidden"
			variants={navAnimation.wrapper}
			transition={{ ease: "easeIn", duration: 0.5 }}
			className="z-mobile-nav fixed left-0 flex h-full w-full flex-[1_1_auto] flex-col divide-y-2 divide-neutral-200 overflow-y-scroll bg-white pb-16 top-[calc(var(--banner-height)_+_60px)]"
		>
			<motion.div variants={navAnimation.inner}>
				<InvestingEntitiesMobileDropdownMenu />
			</motion.div>
			<motion.ul
				variants={navAnimation.inner}
				ref={navRef}
				className="flex w-full flex-col items-stretch justify-start gap-3 px-6 py-4"
			>
				<RenderIfCondition condition={!!hasOffers}>
					<li>
						<Link
							as={RouterLink}
							href="/invest"
							variant={LinkVariant.Black}
							onClick={onClose}
							className={LINK_STYLES}
						>
							<NavItemWithCountPill name="Invest" count={offersCount} />
						</Link>
					</li>
				</RenderIfCondition>
				<RenderIfCondition condition={!!hasSellOrders}>
					<li>
						<Link
							as={RouterLink}
							href="/trade"
							variant={LinkVariant.Black}
							onClick={onClose}
							className={LINK_STYLES}
						>
							<NavItemWithCountPill name="Trade" count={sellOrdersCount} />
						</Link>
					</li>
				</RenderIfCondition>
				<li>
					<Link
						as={RouterLink}
						href="/portfolio"
						variant={LinkVariant.Black}
						onClick={onClose}
						className={LINK_STYLES}
					>
						Portfolio
					</Link>
				</li>
				<BehindFeature feature="CUSTOM_INVESTOR_PORTAL_V1">
					<li>
						<Link
							as={RouterLink}
							href="/orders"
							variant={LinkVariant.Black}
							onClick={onClose}
							className={LINK_STYLES}
						>
							<NavItemWithCountPill name="Orders" count={activeOrdersCount} />
						</Link>
					</li>
				</BehindFeature>
				<li>
					<Link
						as={RouterLink}
						href="/documents"
						variant={LinkVariant.Black}
						onClick={onClose}
						className={LINK_STYLES}
					>
						Documents
					</Link>
				</li>
				<li>
					<Link
						as={RouterLink}
						href="/transactions"
						variant={LinkVariant.Black}
						onClick={onClose}
						className={LINK_STYLES}
					>
						Transactions
					</Link>
				</li>
				<li>
					<Link
						as={RouterLink}
						variant={LinkVariant.Black}
						href="/notifications"
						onClick={onClose}
						className={clsx("gap-x-2", LINK_STYLES)}
					>
						Notifications
						<NotificationsCountPill className="!bg-primary-400 !text-white flex items-center justify-center p-[6px] !h-4 !min-w-[16px] rounded-full text-xs" />
					</Link>
				</li>
			</motion.ul>
			<motion.ul
				variants={navAnimation.inner}
				ref={navRef}
				className="flex w-full flex-col gap-3 px-6 py-4 md:gap-4"
			>
				<li>
					<span className="text-subheading-sm flex py-2 font-semibold text-black">
						My Account
					</span>
				</li>
				<li>
					<Link
						as={RouterLink}
						icon={<IconAccount className="text-icon text-[18px]" />}
						variant={LinkVariant.Black}
						className="text-body-default flex w-full text-black"
						href="/preferences"
					>
						Account preferences
					</Link>
				</li>
				<li>
					<ContactUs
						icon={<IconMailStroke className="text-icon text-[18px]" />}
						className="text-body-default flex w-full text-black"
					>
						Contact us
					</ContactUs>
				</li>
				<li>
					<Link
						icon={<IconHelp className="text-icon text-[20px]" />}
						variant={LinkVariant.Black}
						className="text-body-default flex w-full text-black"
						href={CARUSO_HELP_CENTER}
					>
						Help
					</Link>
				</li>
				<li>
					<Link
						icon={<IconExit className="text-icon text-[18px]" />}
						variant={LinkVariant.Black}
						className="text-body-default flex w-full text-black"
						as="button"
						onClick={logOut}
					>
						Log out
					</Link>
				</li>
				<li>
					<TenantTermsAndConditionsLink
						variant={LinkVariant.Black}
						className="text-body-default flex w-full text-black"
					/>
				</li>
				<li>
					<TenantPrivacyPolicyLink
						variant={LinkVariant.Black}
						className="text-body-default flex w-full text-black"
					/>
				</li>
			</motion.ul>
		</motion.nav>
	);
};

export default DefaultNavigation;
